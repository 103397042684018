/* BUTTONS */

button {
  background: none;
  border: 0;

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    @include box-shadow(none);
    background: none;
    outline: 0;
  }
}

.btn {
  $btn-border: 2px solid;

  @include inline-block;
  @include transition(background-color .25s ease-in-out, color .25s ease-in-out, border-color .25s ease-in-out);
  @include border-radius(rem-calc(100));
  font-size: $rem-base;
  font-weight: $font-weight-bold;
  min-width: rem-calc(205);
  padding: rem-calc(15 20);
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:hover:active {
    @include transition(background-color .25s ease-in-out, color .25s ease-in-out, border-color .25s ease-in-out);
    @include box-shadow(none);
    outline: 0;
  }

  +.btn { margin-left: rem-calc(17); }

  &.btn-primary {
    background-color: $primary-color;
    border: $btn-border $primary-color;
    color: $white;
    border: 0;

    &:hover,
    &:active,
    &:hover:active {
      background-color: $bg-color;
      border-color:$bg-color;
      color: $secondary-text-color;
    }
  }

  &.btn-default {
    background-color: $white;
    border: $btn-border $white;
    color: $secondary-text-color;
  }

  &.btn-empty,
  &.btn-default {
    &:hover,
    &:active,
    &:hover:active {
      background-color: $primary-color;
      border: $btn-border $primary-color;
      color: $white;
    }
  }

  &.btn-empty {
    background-color: $transparent;
    border: $btn-border $white;
  }
}

.label { @include border-radius(0); }
