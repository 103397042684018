// -----------  CUSTOM MIXINS ---------- //

// Prefixer

@mixin prefixer($map, $vendors: webkit moz ms o) {
  @each $property, $value in $map {
    @if $vendors {
      @each $vendor in $vendors {
        #{'-' + $vendor + '-' + $property}: #{$value};
      }
    }
    // Dump regular property anyway
    #{$property}: #{$value};
  }
}

// Rem Calc

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

// Placeholder

@mixin input-placeholder {
  &.placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

// Background

@mixin background($image-path: null, $size: null, $position: null, $repeat: null, $color: null, $overlay: null, $fixed: null) {
  $background-size: if($size, $size, cover);
  $background-position: if($position, $position, center);
  $background-repeat: if($repeat, $repeat, no-repeat);
  $background-attachment: if($fixed, $fixed, initial);

  @include background-size($background-size);

  @if $color { background-color: $color; }
  @if $image-path { background-image: url(#{$image-path}); }

  background-position: $background-position;
  background-repeat: $background-repeat;
  background-attachment: $background-attachment;

  @if $overlay {
    &::before {
      background-color: $overlay;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

// Center Elements

@mixin center-elem($orientation: default) {
  position: absolute;

  @if $orientation {
    @if $orientation == 'horizontal' {
      @include translateX(-50%);
      left: 50%;
    }

    @if $orientation == 'vertical' {
      @include translateY(-50%);
      position: absolute;
      top: 50%;
    }
  }

  @if $orientation == default {
    @include translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}

// Position

@mixin position ($position: relative, $coordinates: 0 0 0 0) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  position: $position;

  @if $top == auto { top: $top; }
  @else if not(unitless($top)) { top: $top; }
  @if $right == auto { right: $right; }
  @else if not(unitless($right)) { right: $right; }
  @if $bottom == auto { bottom: $bottom; }
  @else if not(unitless($bottom)) { bottom: $bottom; }
  @if $left == auto { left: $left; }
  @else if not(unitless($left)) { left: $left; }
}
