/* HOMEPAGE STYLES */

.homepage {
  header {
    background-color: $transparent;
    position: absolute;
  }

  section {
    &.hero {
      @include background('../img/call-to-search-hero-bg.jpg', cover, center top, no-repeat);
      height: rem-calc(690);
      padding-top: rem-calc(170);
      position: relative;

      &::after {
        @include background-image(linear-gradient($secondary-text-color, $transparent));
        @include center-elem;
        content: '';
        height: 100%;
        width: 100%;
      }

      p {
        color: $white;
        font-size: rem-calc(22);
        margin-bottom: rem-calc(30);

        @media screen and (min-width: 40em) { max-width: rem-calc(500); }
      }

      .row {
        position: relative;
        z-index: 1;
      }

      .btn {
        @media screen and (max-width: 39.9375em) {
          margin: rem-calc(0 0 20 0);
          width: 100%;
        }
      }
    }

    &.download {
      background-color: $bg-color;
      padding: rem-calc(35 0 60);

      @media screen and (max-width: 63.9375em) {
        .screenshot {
          display: block;
          margin: rem-calc(60) auto 0;
        }
      }

      @media screen and (min-width: 64em) {
        p,
        h2 { text-align: right; }

        p {
          float: right;
          margin-bottom: rem-calc(35);
          max-width: rem-calc(395);
        }

        h2 { margin: rem-calc(100 0 20); }

        .row { max-width: rem-calc(1000); }
        .columns { padding: rem-calc(0 35); }
      }

      .apple-badge {
        width: rem-calc(155);

        @media screen and (min-width: 64em) { float: right; }
      }
    }

    &.help {
      padding: rem-calc(120 0);

      p { margin-bottom: rem-calc(35); }
      h2 { margin: rem-calc(15 0 20); }


      @media screen and (max-width: 63.9375em) {
        padding: rem-calc(60 0);

        h2 { margin: rem-calc(0 0 10); }

        .screenshot {
          display: block;
          margin: 0 auto rem-calc(30);
        }
      }

      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        .screenshot { max-width: rem-calc(600); }
      }

      @media screen and (min-width: 64em) {
        .row { max-width: rem-calc(1200); }

        .columns + .columns { padding-left: rem-calc(65); }

        p { max-width: rem-calc(395); }

        img {
          position: relative;
          right: -35px;
        }
      }
    }

    &.help,
    &.download {
      article.columns {
        @media screen and (max-width: 63.9375em) {
          float: none;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}
