// 1. Global
// ---------

$rem-base: 16px !default;
$global-font-size: 100%;
$global-lineheight: 1.5;
$global-width: 77.375rem;
$map-page-width: 95.625rem;
$header-height: 5.625rem;

// Grayscale

$white:    #ffffff;
$black:    #000000;
$black-02: darken($white, 02%) !default;
$black-10: darken($white, 10%) !default;
$black-20: darken($white, 20%) !default;
$black-30: darken($white, 30%) !default;
$black-35: darken($white, 34%) !default;
$black-40: darken($white, 40%) !default;
$black-50: darken($white, 50%) !default;
$black-60: darken($white, 60%) !default;
$black-70: darken($white, 70%) !default;
$black-80: darken($white, 80%) !default;
$black-90: darken($white, 90%) !default;

// Colors

$transparent: transparent;
$base-color: #747680;
$primary-color: #5faab2;
$secondary-color: #9a1c20;
$success-color: #6e9e30;
$warning-color: #ffae00;
$alert-color: #ec5840;
$red: #f00;
$blue: #00f;
$light-gray: #e6e6e6;
$medium-gray: $black-20;
$dark-gray: $black-50;
$contact-background: #f0f2f8;
$body-background: $white;
$input-background: #434653;
$input-border-color: #dbdde0;
$transparent-input-color: #fefefe;
$video-background: #0a0c0e;
$offers-background: #f3f4f9;
$body-font-color: #2d303c;
$border-color: #d8dbe7;
$border-shadow: rbga($black-90, .8);
$invalid-color: #e74c3c;
$secondary-text-color: #505455;
$bg-color: #f2f1f1;
$border-color: #e5e5e5;
$paragraph-color: #6d6d72;
$invalid-color: #e74c3c;
$highlight-color: #00f498;
$highlight-bg-color: #d5ffef;

// Grayscale

$black-10:  darken($white, 10%) !default;
$black-20:  darken($white, 20%) !default;
$black-30:  darken($white, 30%) !default;
$black-35:  darken($white, 34%) !default;
$black-40:  darken($white, 40%) !default;
$black-50:  darken($white, 50%) !default;
$black-60:  darken($white, 60%) !default;
$black-70:  darken($white, 70%) !default;
$black-80:  darken($white, 80%) !default;
$black-90:  darken($white, 90%) !default;

// Transition timings

$transition-fast: .25s;
$transition-normal: .5s;
$transition-slow: 1s;
$transition-in-out: ease-in-out;
$transition-in: ease-in;
$transition-out: ease-out;
$transition-default: $transition-fast $transition-in-out;
$single-transition-default: $transition-fast, $transition-in-out;

// We use these to define default font weights

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$body-font-family: 'Source Sans Pro', sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: $font-weight-normal;
$global-weight-bold: $font-weight-bold;
$global-radius: 0;
$global-text-direction: ltr;
$global-flexbox: false;
$print-transparent-backgrounds: true;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  document: 960px,
  large: 1030px,
  xlarge: 1200px,
  xxlarge: 1450px,
);

$breakpoint-classes: (small medium large xlarge xxlarge);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 40px,
  medium: 30px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: $font-weight-light;
$header-font-style: normal;
$font-family-monospace: 'Envy Code R', Courier, monospace;
$header-sizes: (
  small: (
    'h1': 15,
    'h2': 28,
    'h3': 19,
    'h4': 18,
    'h5': 17,
    'h6': 15,
  ),
  medium: (
    'h1': 52,
    'h2': 40,
    'h3': 28,
    'h4': 24,
    'h5': 22,
    'h6': 15,
  ),
);
$header-color: inherit;
$header-lineheight: 1.2;
$header-margin-bottom: .5rem;
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $white;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: .3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------

$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $black-50;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: rem-calc(20);
$subheader-margin-bottom: .5rem;
$stat-font-size: 2.5rem;

