/* FOOTER STYLES */

footer {
  @include clearfix;
  background-color: $primary-color;
  display: block;
  padding: rem-calc(20 36);
  width: 100%;

  @media screen and (max-width: 39.9375em) { padding: rem-calc(20 15); }

  a {
    font-weight: $font-weight-semibold;
    position: relative;

    &:hover,
    &:focus,
    &:active:focus {
      @include opacity(.8);
      color: $white;
    }
  }

  img { width: rem-calc(34); }

  nav {
    padding: 0;
    text-align: center;

    @media screen and (min-width: 64em) {
      margin-top: rem-calc(6);
      text-align: left;
    }

    ul li {
      @include inline-block;
      margin-right: rem-calc(15);

      &:last-of-type {
        margin-right: 0;

        a {
          padding-right: 0;

          &::after { display: none; }
        }
      }

      a {
        display: block;
        padding-right: rem-calc(18);

        &::after {
          background-color: $white;
          content: '';
          display: block;
          height: rem-calc(14);
          position: absolute;
          right: 0;
          top: rem-calc(6);
          width: rem-calc(2);
        }
      }
    }
  }

  .contact {
    margin-top: rem-calc(15);
    padding: 0;
    text-align: center;

    @media screen and (min-width: 64em) { margin-top: rem-calc(6); }

    a {
      margin-right: rem-calc(30);

      &:last-of-type {
        margin-right: 0;

        img { height: rem-calc(18); }
      }
    }

    img {
      margin-right: rem-calc(8);
      height: rem-calc(15);
      width: auto;
    }
  }

  .social {
    padding: 0;
    text-align: center;

    a {
      @include border-radius(100%);
      @include inline-block;
      background-color: $white;
      height: rem-calc(36);
      margin: rem-calc(20 17 0 0);
      width: rem-calc(36);

      @media screen and (min-width: 64em) {
        float: right;
        margin: rem-calc(0 17 0 0);
        text-align: right;
      }

      &:last-of-type {
        margin-left: 0;

        img { width: rem-calc(12); }
      }

      img {
        @include center-elem;
        width: rem-calc(20);
      }
    }
  }
}
