/* PARAGRAPH */

h1,
h2,
h3 {
  font-weight: $font-weight-semibold;
  letter-spacing: .7px;
}

h1 {
  color: $white;
  font-size: rem-calc(58);
  margin-bottom: rem-calc(12);
}

h2 {
  color: $primary-color;
  font-size: rem-calc(36);
}

h3 {
  color: $primary-color;
  font-size: rem-calc(20);
}

p {
  color: $paragraph-color;
  font-size: rem-calc(18);
  line-height: 1.4;
}
