/* DYNAMIC PAGES STYLES */

.dynamic-page {
  background-color: $bg-color;

  section {
    background-color: $white;
    margin: rem-calc(86) auto rem-calc(76);
    max-width: rem-calc(800);
    padding: rem-calc(45 105);

    @media screen and (max-width: 39.9375em) {
      margin: 0;
      padding: rem-calc(20 15);
    }

    h2 {
      text-align: center;
      margin-bottom: rem-calc(35);

      @media screen and (max-width: 39.9375em) {
        margin-bottom: rem-calc(25);
        line-height: normal;
      }
    }

    nav {
      background-color: $bg-color;
      height: rem-calc(35);
      margin: 0 auto rem-calc(30);
      padding: 0;
      position: relative;
      width: 95%;

      @media screen and (max-width: 39.9375em) {
        height: rem-calc(70);
        text-align: center;
        width: 100%;
      }

      ul {
        @include center-elem;
        font-size: 0;
        width: 100%;

        @media screen and (max-width: 39.9375em) {
          margin: 0 auto;
          width: 70%;
        }
      }

      li {
        @include inline-block;
        width: 50%;

        @media screen and (max-width: 39.9375em) {
          display: block;
          width: 100%;
          padding: rem-calc(5);
        }

        button {
          color: $paragraph-color;
          cursor: pointer;
          font-family: Source Sans Pro,sans-serif;
          font-size: rem-calc(18);
          font-weight: $font-weight-semibold;
          position: relative;
          padding-right: rem-calc(18);

          &.selected {
            color: $primary-color;
            font-weight: $font-weight-bold;
          }

          &::after {
            background-color: $paragraph-color;
            content: '';
            display: block;
            height: rem-calc(18);
            position: absolute;
            right: 0;
            top: 0;
            width: rem-calc(2);
          }
        }

        &:first-child {
          text-align: right;

          @media screen and (max-width: 39.9375em) {
            border-bottom: rem-calc(1) solid $paragraph-color;
            padding-bottom: rem-calc(4);
            text-align: center;

            button {
              padding-right: 0;

              &::after { display: none }
            }
          }
        }

        &:last-of-type {
          margin-right: 0;
          padding-left: rem-calc(15);

          button {
            padding-right: 0;

            &::after { display: none }
          }

          @media screen and (max-width: 39.9375em) { padding-left: 0; }
        }
      }
    }

    [id$="-content"] {
      a {
        color: $primary-color;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active:focus { color: rgba($primary-color, .8); }
      }
    }
  }

  .question {
    color: $primary-color;
    font-size: rem-calc(19);
    font-weight: $font-weight-semibold;
    margin: rem-calc(0 105 14);
  }

  .answer { margin: rem-calc(0 105 35); }
}
