/* HEADER */

$header-height: rem-calc(70);

header {
  background-color: $primary-color;
  display: block;
  height: $header-height;
  padding: rem-calc(20 36);
  position: relative;
  width: 100%;
  z-index: 1;

  nav {
    float: right;

    ul li {
      @include inline-block;
      margin-left: rem-calc(35);

      &:nth-last-of-type(-n+2) {
        @media screen and (max-width: 39.9375em) { display: none; }
      }

      a {
        color: $white;
        font-weight: $font-weight-semibold;
      }
    }
  }

  .logo {
    &:hover,
    &:focus,
    &:active:focus { @include opacity(.8); }
  }
}
