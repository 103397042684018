/* BASE */

html { font-size: $rem-base; }

body {
  background-color: $white;
  font-family: $body-font-family;
}

section {
  min-height: rem-calc(530);
  overflow: initial;
}

a {
  color: $white;
  outline: 0;

  &:hover,
  &:focus,
  &:active:focus {
    color: rgba($white, .8);
    outline: 0;
    text-decoration: none;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
